html {
  background-color: #282c34;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* From tic-tac-toe */
body {
  font: 14px "Century Gothic", Futura, sans-serif;
}

ol,
ul {
  padding-left: 30px;
}

@media only screen and (max-width: 600px) {
  :root {
    --cell-side-length: 12px;
  }
}

@media only screen and (min-width: 601px) {
  :root {
    --cell-side-length: 20px;
  }
}

.board-row {
  height: var(--cell-side-length);
  vertical-align: top;
}

/* .board-row:after {
  clear: both;
  content: "";
  display: table;
} */

.status {
  margin-bottom: 10px;
}

.square {
  /* background: #fff; */
  /* background: #777; */
  border: 1px solid #555;
  position: relative;
  /* float: left; */
  line-height: var(--cell-side-length);
  height: var(--cell-side-length);
  margin: -1px;
  padding: 0;
  width: var(--cell-side-length);
}

.square-dead {
  background: #777;
}

.square-alive {
  /* background: #444; */
  /* background: #61dbfb; */
  background: #ff9800;
}

.square:focus {
  outline: none;
}

.square-dead:hover {
  /* background: #ffcc80; */
  background: #c66900;
}

.button-history {
  font-weight: 500;
  margin: 30px;
  padding: 30px;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

/* .game { */
/*   display: flex; */
/*   align-items: center; */
/*   flex-direction: column; */
/* } */

.game-info {
  margin: 20px;
}

.control-strip {
  margin: 20px;
  /* padding: 20px; */
  /* flex-grow: 1; */
  /* flex-shrink: 1; */
}

.icon-flipped {
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}
